@font-face {
    font-family: 'Lato';
    src: url('/assets/minor/brands/fonts/lato-bold.eot');
    src: url('/assets/minor/brands/fonts/lato-bold.eot?#iefix') format('embedded-opentype'),
         url('/assets/minor/brands/fonts/lato-bold.woff2') format('woff2'),
         url('/assets/minor/brands/fonts/lato-bold.woff') format('woff'),
         url('/assets/minor/brands/fonts/lato-bold.ttf') format('truetype'),
         url('/assets/minor/brands/fonts/lato-bold.svg#lato') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/assets/minor/brands/fonts/lato-medium.eot');
    src: url('/assets/minor/brands/fonts/lato-medium.eot?#iefix') format('embedded-opentype'),
         url('/assets/minor/brands/fonts/lato-medium.woff2') format('woff2'),
         url('/assets/minor/brands/fonts/lato-medium.woff') format('woff'),
         url('/assets/minor/brands/fonts/lato-medium.ttf') format('truetype')
         url('/assets/minor/brands/fonts/lato-medium.svg#lato') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/assets/minor/brands/fonts/lato-regular.eot');
    src: url('/assets/minor/brands/fonts/lato-regular.eot?#iefix') format('embedded-opentype'),
         url('/assets/minor/brands/fonts/lato-regular.woff2') format('woff2'),
         url('/assets/minor/brands/fonts/lato-regular.woff') format('woff'),
         url('/assets/minor/brands/fonts/lato-regular.ttf') format('truetype')
         url('/assets/minor/brands/fonts/lato-regular.svg#lato') format('svg');
    font-weight: normal;
    font-style: normal;
}

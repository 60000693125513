body.oaks {
    // Header
    .header-primary {
        .logo {
            &__image {
                .iframe & {
                    display: none;
                }

                &--alternate {
                    .iframe & {
                        display: block;
                    }
                }
            }
        }
    }

    .header-primary.horizon {
        .myprivilage {
            .constraint {
                #myProfile {
                    color: $t-gold;
                }
            }
        }

        .personalization-item {
            &::after {
                background-color: $f-gold;
            }
        }
        .discoveryProfile {
            > div:first-child {
                &::before {
                    background-color: $f-gold;
                }
            }
            .discoveryTier {
                &::after {
                    top: 15px;
                    @include desktop {
                        top: 8px;
                    }
                }
            }
            .discoveryDollar {
                &::after {
                    background-color: $f-gold;
                }
            }
        }

        &.is-scrolled {
            @include desktop {
                background-color: transparent;
                box-shadow: none;
            }

            @media (min-width: #{$bpMinDesktop}) and (max-width: 1150px) {
                padding-top: 0;
            }

            .header-toolbar {
                @include no-desktop {
                    height: 38px;
                }

                @include desktop {
                    // height: 19px;
                }
            }

            .logo {
                &__image {
                    display: block;

                    &--alternate {
                        display: none;
                    }
                }
            }

            .myprivilege {
                .constraint {
                    #myProfile {
                        @include no-desktop {
                            top: -8px;
                        }

                        @include tablet {
                            top: -16px;
                        }

                        @include desktop {
                            top: -105px;
                        }
                    }

                    .discoveryProfile {
                        .discoveryTier {
                            @include desktop {
                                padding-top: 0px;
                            }
                            &::after {
                                @include desktop {
                                    top: 8px;
                                }
                            }
                            
                            .red {
                                color: #ff4444;
                            }

                            .titanium {
                                color: #23282D;
                            }

                            .platinum {
                                color: #bbbcc3;
                            }

                            .gold {
                                color: #a59451;
                            }

                            .silver {
                                color: #c0c0c0;
                            }
                        }
                        .discoveryDollar {
                            @include desktop {
                                padding-top: 0px;
                            }
                        }
                    }
                }
            }

            .language-selector {
                .language-bar {
                    @include desktop {
                        top: 60px;
                    }
                }
                .mobile-language-toggle {
                    @include desktop {
                        margin-top: 0px;
                    }
                }

                &::after {
                    top: 12px;
                    @include desktop {
                        top: 0px;
                    }
                }
            }

            .header-inside {
                @include no-desktop {
                    position: absolute;
                    transform: translateY(-105px);
                }
            }

            .menu-container {
                @include desktop {
                    padding-top: 4px;
                }
            }

            .slinky-menu {
                @include desktop {
                    ul li {
                        padding: 0 10px;
                    }
                }
            }

            .account__link {
                @include desktop {
                    margin-top: 0px;
                }
            }

            .personalization-item {
                @include desktop {
                    margin-block: 0px;
                }
            }

            .panel-currency {
                @include desktop {
                    margin-top: 0px;
                }
                .currencyMenu {
                    @include desktop {
                        top: 38px;
                    }
                }
            }
        }

        .account {
            &__link {
                color: $t-gold;

                &::after {
                    background-color: $f-gold;
                }
            }

            &__name {
                @include no-desktop {
                    display: block;
                    margin-bottom: 32px;
                    font-size: 18px;
                    font-weight: 500;
                    color: white;
                }
            }
        }

        .account-mobile {
            &__link {
                &::after {
                    background-color: white;
                }
            }
        }

        .language-selector {
            &::after {
                background-color: $f-gold;
            }

            .mobile-language-toggle {
                color: $t-gold;

                &:after {
                    border-top-color: $f-gold;
                    top: 6px;
                }

                &.active {
                    &:after {
                        border-bottom-color: $f-gold
                    }
                }
            }

            .language-bar {
                background: $f-bright-blue;
                @include tablet {
                    top: 38px;
                }
                @include desktop {
                    top: 65px;
                }

                .t-gold {
                    color: white !important;
                }

                .t-underline {
                    &::after {
                        height: 0;
                    }
                }
            }
        }

        .panel-currency {
            &::after {
                background-color: $f-gold;
            }
            .currencyMenu {
                background-color: $f-bright-blue;
                @include tablet {
                    right: -20px;
                    top: 27px;
                }
                @include desktop {
                    top: 40px;
                }


                .currency_list li .currency-item .currency-code {
                    color: white;
                }
            }

            .t-gold {
                color: white !important;
            }

            .t-underline {
                &::after {
                    height: 0;
                }
            }

            .trigger {
                color: $t-gold;

                &:after {
                    border-top-color: $f-gold;
                    top: 6px;
                }

                &.active {
                    &:after {
                        border-bottom-color: $f-gold;
                    }
                }
            }

            .currency-converter {
                color: $t-gold;

                select {
                    color: $t-gold;
                }
            }
        }

        .currency-converter {
            .form {
                .form-main,
                .trigger {
                    &:after {
                        border-top-color: $f-gold;
                        @include mobile {
                            top: 5px;
                        }
                    }
                }
            }
        }

        .myprivilege {
            .constraint {
                #myProfile {
                    color: $t-gold;

                    @include no-desktop {
                        color: white;
                    }

                    a {
                        color: $t-gold;

                        @include no-desktop {
                            color: white;
                        }
                    }

                    .menu {
                        background-color: $f-bright-blue;

                        a {
                            color: white;
                        }
                    }
                }

                .discovery {
                    &Profile {
                        .discoveryTier,
                        .discoveryDollar {
                            &:after {
                                @include no-desktop {
                                    background-color: white;
                                }
                            }
                        }
                    }
                }
            }
        }

        .logo {
            width: 75px;

            @include desktop {
                width: 180px;
            }

            @include tablet {
                min-height: 18px;
            }

            &__link {
                height: 18px;

                @include desktop {
                    height: auto;
                }
            }

            &__image {
                max-height: 18px;
                display: block;

                @include desktop {
                    max-height: 26px;
                }

                &--alternate {
                    display: none;
                }
            }
        }
    }
    // Header - Light Background
    .header-primary--light-bg,
    .site-container.no-banner {
        .slinky-menu {
            .menu__item {
                color: white;
                &.active {
					a.menu__item__link {
                        border-bottom: 1px solid white;
                    }
				}
            }

            ul > li > a {
                &:hover,
                &:focus {
                    color: $t-standard;

                    @include desktop {
                        color: $t-standard-on-gold;
                    }
                }
            }

            .js-destination {
                &::after {
                    @include desktop {
                        border-top-color: white;
                    }
                }

                &:hover,
                &:focus {
                    &::after {
                        @include desktop {
                            border-top-color: $t-standard-on-gold;
                        }
                    }
                }
            }
        }
    }
    // Mobile Hamburger Menu
    .c-hamburger {
        span {
            background-color: white;
        }
    }

    .is-active {
        .c-hamburger {
            span {
                background-color: transparent;
            }
        }
    }

    .horizon .booking-room .c-hamburger {
        span {
            background-color: transparent;
        }

        span:before,
        span:after {
            @include desktop {
                background-color: $f-gold;
            }
        }
    }
    // Booking Section
    .header-booking-mask {
        background-color: $f-bright-blue;

        @include no-desktop {
            .booking-trigger {
                background-color: $f-green;
            }

            &.is-active {
                .btn--gold {
                    background-color: $f-bright-blue;
                }
            }
        }
    }
	
	.horizon .calendar-dimmer {
		display:none!important;
	}
    // Booking Mask
    .booking-section {
        .stepper {
            &-btn {
                background-color: $f-green;
            }
        }

        .button__submit {
            background-color: $f-green;

            &:hover {
                background-color: #0c7565;
            }
        }

        .booking-form-guest-group {
            border-radius: 8px;

            @include desktop {
                background-color: $f-bright-blue;
            }
        }

        .booking-form-subgroup {
            border-radius: 4px;
            height: 45px !important;
            margin-top: 0px !important;
        }

        .promo {
            &__container {
                border-radius: 8px;

                @include desktop {
                    background-color: $f-bright-blue;
                }

                &::after {
                    @include no-desktop {
                        border: 0;
                    }
                }

                &-wrapper {
                    border-radius: 4px;
                }
            }
        }
        // Mobile and Tablet
        @include no-desktop {
            background-color: $f-bright-blue;

            .date {
                &__group {
                    background-color: $f-blue-faint;
                }
            }

            .booking-guest {
                .booking-form-subgroup {
                    background-color: $f-blue-faint;
                }
            }

            .booking-promo {
                .booking-form-subgroup {
                    background-color: $f-bright-blue;
                }
            }
        }
    }
    // Menu
    .menu-container {
        &.is-active::before {
            box-shadow: none;
            height: 0;
        }

        @include desktop {
            padding-top: 4px;
            padding-bottom: 4px;
            background-color: $f-gold;
            transform: translateY(0);
            transition: all $headerAnimation $headerEasing;
        }
    }

    .slinky-menu {
		ul {
			li {
				&.active {
					a.menu__item__link {
                        border-bottom: 1px solid white;
                    }
				}
			}
		}
		
        @include no-desktop {
            ul {
                li {
                    a {
                        background: white;
                        border-bottom-color: #9EA4A9;
                        text-transform: capitalize !important;
                        font-weight: 700;
                        font-size: 16px;

                        &.js-destination {
                            margin: 0;
                        }
                    }

                    &:first-child {
                        .back,
                        > a {
                            padding-top: 17px;
                        }
                    }

                    .js-destination::after {
                        top: 19px;
                    }
                }
            }

            a {
                color: $t-gold;
            }

            .next {
                &::after {
                    @include border-left(10px solid $t-gold);
                }

                &.has-link {
                    background-color: white;
                    border-bottom-color: #9EA4A9;
                    @include border-left(1px solid #9EA4A9);
                    margin-top: -53px;
                    height: 53px;
                }
            }

            .back {
                &::before {
                    @include border-right(6px solid $t-gold);
                }
            }
        }

        @include desktop {
            ul li {
                text-shadow: none;

                .js-destination::after {
                    top: 5px;
                }
            }
        }

        @include mobile{
            .brand-home > a {		
                &:before {
                    @include border-right(8px solid $f-gold);
                }
            }
        }
    }

    .header-primary--light-bg {
        .slinky-menu {
            ul {
                > li {
                    > a.active, &:hover, &:focus {
                        color: $f-grey-light;
                    }
                }
            }
        }
    }
    // Call Travel Agent
    #callTA {
        @include no-desktop {
            bottom: 60px;
        }

        p {
            border-top: $t-gold;
        }
    }
    // Typography
    .t-xl {
        font-weight: 600;
    }

    .t-c-m {
        a:not(.btn, .text-cta) {
            color: $f-green;
        }
    }

    .t-s,
    .t-xs {
        letter-spacing: 1px;
    }

    .t-gold,
    .t-green {
        color: $f-green !important;
    }
    // Button
    .btn {
        font-weight: 600;
        letter-spacing: 1px;

        &--gold {
            background: $f-green;
            font-weight: 600;
            letter-spacing: 1px;

            &:hover,
            &:focus {
                background: #0c7565;
            }
        }

        &--gold-transparent {
            background-color: transparent;
            border: 1px solid $f-gold;
            color: $t-standard;
            letter-spacing: 1px;

            &:hover,
            &:focus {
                background-color: $f-gold;
                color: white;
            }
        }

        &--ghost {
            background: transparent;
            border-color: $f-gold;

            &:hover,
            &:focus {
                background-color: $f-gold;
                color: white;
            }
        }

        &--lock {
            span {
                background-color: $f-green;
                font-weight: 700;
                letter-spacing: 0;

                &::before {
                }
            }

            &::after {
            }
        }
    }

    .button {
        &-oaks {
            &-green {
                background: $f-green;
                font-weight: 600;
                letter-spacing: 1px;
    
                &:hover,
                &:focus {
                    background: #0c7565;
                }

                &-border {
                    color: $f-green;
                    background-color: transparent;
                    border: 1px solid $f-green;

                    &:hover, 
                    &:focus {
                        color: #fff !important;
                        background: $f-green;
                    }

                    &:focus {
                        background: #0c7565;
                    }
                }
            }

            &-blue {
                color: #fff !important;
                background-color: $f-gold;
                
                &:hover, 
                &:focus {
                    background: darken($f-gold, 7%);
                }
                
                &-border {
                    color: $f-gold !important;
                    background-color: transparent;
                    border: 1px solid $f-gold;

                    &:hover, 
                    &:focus {
                        color: #fff !important;
                        background: $f-gold;
                    }

                    &:focus {
                        background: darken($f-gold, 7%);
                    }
                }
            }
        }
    }

    // Grid Content
    &.horizon-header {
        main > .content {
            @include desktop {
                margin-top: 161px;
            }

            &.is-scrolled {
                @include desktop {
                    margin-top: 195px;
                }
            }
        }

        &.hide-booking-mask {
            main > .content {
                @include desktop {
                    margin-top: 95px;
                    padding-top: 0px;
                }

                &.is-scrolled {
                    @include desktop {
                        margin-top: 195px;
                    }
                    .tabs.is--sticky {
                        top: 38px !important;
                        @include desktop {
                            top: 60px !important;
                        }
                    }
                }
                .tabs.is--sticky {
                    margin: 0;
                    top: 96px !important;
                }
            }

            &.with-notice {
                main > .content {
                    @include desktop {
                        margin-top: 145px;
                        padding-top: 0px;
                    }

                    &.is-scrolled {
                        @include desktop {
                            margin-top: 195px;
                        }
                        .tabs.is--sticky {
                            margin: 0;
                            top: 38px !important;
                            @include desktop {
                                top: 60px !important
                            }
                        }
                    }
                    .tabs.is--sticky {
                        margin: 0;
                        top: 148px !important;
                    }
                }
            }

            .section--ptt {
                padding-top: unset !important; 
            }
        }

        &.with-notice {
            main > .content {
                @include desktop {
                    margin-top: 213px;
                }

                &.is-scrolled {
                    @include desktop {
                        margin-top: 178px;
                    }
                }
            }
        }
    }
    // Table
    .responsive-table {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: 700;
        }
    }
    // Section
    .section {
        &--dark-grey {
            color: $t-standard;

            + .section--same-as-previous {
                color: $t-standard;
            }
        }

        &--dark-grey-gradient {
            background-image: linear-gradient($f-mint, #333);
        }

        &-header {
            z-index: 0;

            &__title {
                color: $f-green;
                letter-spacing: 1px;
            }

            &__text {
                text-transform: capitalize;

                @include desktop {
                    font-size: 2.8em;
                }
            }
        }

        &-footer {
            &__text {
                color: $t-gold;
            }
        }
    }
    // Destination Menu
    .destinations {
        background: white;

        a {
            color: $f-gold;
        }

        &__menu {
            > ul {
                > li {
                    -webkit-column-break-inside: auto;
                    page-break-inside: auto;
                    break-inside: auto;

                    &.avoid-break-inside {
                        -webkit-column-break-inside: avoid;
                        page-break-inside: avoid;
                        break-inside: avoid;
                    }

                    > a {
                        color: $f-green;

                        &.view-all {
                            background-color: $f-green;
                            color: white;

                            &:hover {
                                background-color: #0c7565;
                            }
                        }
                    }

                    &.has-cities {
                        > ul {
                            > li {
                                -webkit-column-break-inside: avoid;
                                page-break-inside: avoid;
                                break-inside: avoid;

                                > a {
                                    color: $f-green;
                                }
                            }
                        }

                        &.is-fallback {
                            > ul {
                                > li {
                                    -webkit-column-break-inside: auto;
                                    page-break-inside: auto;
                                    break-inside: auto;
                                }
                            }
                        }
                    }

                    .fallback-title {
                        color: $f-green;
                    }
                }
            }
        }
    }

    .destination-explorer .full-bg--has-overlay {
        .title {
            color: $t-gold !important;
        }
    }
    // Text-Reveal
    .other-offers,
    .offers {
        .slick-slide {
            border-left-color: transparent;
            border-right-color: transparent;
        }
    }

    .section--dark-grey {
        .other-offers,
        .offers {
            .slick-slide {
                border-color: transparent;
            }
        }
    }

    .t-underline {
        &::after {
            background-color: $f-green;
            @include margin-left(0);
        }
    }

    .text-reveal {
        .text-cta {
            @include margin-left(0);
        }

        &__title {
            font-weight: 600;
        }

        &__benefits {
            &Item {
                &::before {
                    color: $f-green;
                }
            }
        }

        &__from-text {
            letter-spacing: 1px;
            font-weight: 700;
            font-size: 1.1em;
        }

        &:not(.text-reveal--tall, .always-revealed) {
            .text-reveal {
                &__wrapper {
                    @include mobile {
                        color: $t-standard;
                    }
                }

                &__title {
                    @include mobile {
                        color: $t-standard;
                    }
                }

                &__sub-title {
                    @include mobile {
                        color: $t-standard;
                    }
                }

                &__description {
                    @include mobile {
                        color: $t-standard;
                    }
                }

                &__from-text {
                    @include mobile {
                        color: $t-standard;
                    }
                }
            }
        }

        &.always-revealed {
            .text-reveal {
                &__bookingBenefits {
                    color: white;

                    &Title {
                        color: white;
                    }
                }

                &__from-text {
                    letter-spacing: 1px;
                    font-weight: 700;
                    font-size: 1.1em;
                }

                &__cta {
                    .btn--ghost {
                        border-color: white;

                        &:hover,
                        &:focus {
                            background-color: white;
                            color: $t-standard;
                        }
                    }
                }
            }
        }

        &.is-card {
            &__display {
                .currency-converter {
                    select {
                        color: $f-green;
                    }
                }

                .text-reveal {
                    &__bookingBenefits {
                        color: $t-gold;

                        &Title {
                            color: $t-gold;
                        }
                    }

                    &__location {
                        color: $t-standard;
                        text-transform: capitalize;
                        font-weight: 600;
                    }
                }

                .btn--ghost {
                    border-color: $f-gold !important;

                    &:hover,
                    &:focus {
                        background-color: $f-gold !important;
                    }
                }
            }
        }
    }
    // Currency Converter
    .currency-converter {
        select {
            border-bottom-color: $f-green;
            color: $f-green;
        }

        + .item-price {
            font-weight: 700;
        }

        .form {
            .form-main,
            .trigger {
                &:after {
                    border-top-color: $f-green;
                }
            }
        }
    }

    .listing-with-gallery,
    .content-intro-complex,
    .dynamic-list__content,
    .compare-modal__price {
        .currency-converter {
            .form {
                .form-main,
                .trigger {
                    &:after {
                        border-top-color: $f-green;
                    }
                }
            }

            select {
                font-weight: 700;
            }
        }
    }
    // Text CTA
    .text-cta {
        &--explore {
            &::before {
                border-left-color: $f-green;
            }

            &::after {
                border-right-color: $f-green;
            }
        }

        &--more-details {
            display: inline-block;
            background-color: $f-green;
            color: white;
            text-align: center;
            letter-spacing: 0;
            text-transform: uppercase;
            padding: 12px 16px;
            font-weight: 600;
            letter-spacing: 1px;

            &:hover,
            &:focus {
                transform: translateX(0);
            }

            &::before {
                border: 0;
                padding: 0;
            }
        }
    }
    // Video CTA
    .video-cta {
        &__link:before {
            border: 0;
            background-color: rgba(0, 0, 0, 0.8);
            height: 60vw;
            width: 100vw;
            z-index: 0;
        }

        &__heading {
            color: $f-green !important;
        }

        > .content {
            margin: 0;
        }
    }
    // Triangle CTA
    .triangle-cta {

        &--odd:before,
        &:before {
            border: 0;
            background-color: rgba(0, 0, 0, 0.8);
            width: calc(300px * 1.66);
            display: block;

            @include mobile {
                height: calc(300px * 1.66);
                top: 40%;
                border-radius: 50%;
            }
        }

        &:before {
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;

            @include no-mobile {
                border-top-left-radius: 0;
                border-bottom-right-radius: 50%;

                @include rtl {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-left-radius: 50%;
                    border-bottom-left-radius: 50%;
                }
            }
        }

        &--odd:before {
            @include no-mobile {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border-top-left-radius: 50%;
                border-bottom-left-radius: 50%;

                @include rtl {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                    border-top-right-radius: 50%;
                    border-bottom-right-radius: 50%;
                }
            }
        }

        &__heading {
            color: white;
        }

        &__title {
            color: white !important;

            @include no-mobile {
                font-size: 61px;
            }
        }

        &__text {
            @include no-mobile {
                max-width: 350px;
            }
        }
    }
    // Full Bg Banner/Signature
    .centered-content,
    .section--center-content {
        .section-header {
            &__title {
                color: white;
            }
        }

        &__container {
            @include tablet {
                max-width: calc(90vw - 200px);
            }

            @include desktop {
                max-width: calc(60vw - 280px);
            }
        }

        &--boxed {
            position: relative;
            padding: 32px;

            @include desktop {
                padding: 0;
            }

            &::before,
            &::after {
                content: '';
                background-color: rgba(0, 0, 0, 0.8);
                top: 0;
                bottom: 0;
                width: 0;
                height: 100%;
                position: absolute;

                @include tablet {
                    display: block;
                    width: 100px;
                }

                @include desktop {
                    display: block;
                    width: 140px;
                }
            }

            &::before {
                right: 100%;
                border-radius: 100% 0 0 100%/50% 0 0 50%;
            }

            &::after {
                left: 100%;
                border-radius: 0 100% 100% 0/0 50% 50% 0;
            }
        }
    }
    // Business Travel
    .business-travel-cta {
        p.t-center.t-white {
            @include mobile {
                position: absolute;
                width: 1px;
                height: 1px;
                padding: 0;
                margin: -1px;
                overflow: hidden;
                clip: rect(0 0 0 0);
                border: 0;
            }
        }
    }
    // Trending Location
    .trending-location {
        .dynamic-list,
        .static-list {
            &__extra-links {
                .text-cta {
                    @include margin-left(0);
                }
            }
        }
    }
    // Dynamic/Static Listing

    .dynamic-list,
    .static-list {
        &__pagination {
            & > li {
                &.active {
                    background-color: $f-green;

                    &:hover,
                    &:focus {
                        background-color: #0c7565;
                    }

                    a {
                        color: white;
                    }
                }

                &:hover,
                &:focus {
                    background-color: $f-op50-grey-dark;
                }

                a {
                    color: $t-standard;
                }
            }
        }

        &__icon {
            i {
                color: $t-standard;
            }

            &Text {
                &::after {
                    background-color: $t-standard;
                }
            }
        }

        &__text-container {
            .dynamic-list,
            .static-list {
                &__location {
                    color: $t-standard;
                    font-weight: 600;
                }

                &__sub-title {
                    font-weight: 700;
                }
            }
        }
    }
    // Footer
    footer {
        background: $f-gold;

        .footer {
            &-middle {
                border-top: 0;
            }

            &-hotels {
                background: $f-gold;
				border-top: 0;
                .footer-brand__item {
                    background: $f-gold !important;
                }
            }

            &-bottom {
                border-top: 1px solid $f-op75-white;
                background: $f-gold;
            }

            &-detail-links {
                a {
                    color: white;
                }
            }
        }
    }

    .main-footer-brand {
        @include desktop {
            @include border-right(1px solid white);
        }

        @include landscapeTablet {
            @include border-right(1px solid white);
        }
    }

    .engagement {
        &__link {
            i {
                color: $t-standard;
            }

            &:hover,
            &:focus {
                i {
                    color: $f-grey-mid;
                }
            }

            &.dark {
                i {
                    color: $t-standard;
                }

                &:hover,
                &:focus {
                    i {
                        color: white;
                    }
                }
            }
        }
    }

    .full-bg,
    .footer-container {
        .engagement {
            &__link {
                i {
                    color: white;
                }

                &:hover,
                &:focus {
                    i {
                        color: $f-grey-mid;
                    }
                }
            }
        }
    }

    .copyright {
        color: white;
    }

    .newsletter {
        .input-text {
            background-color: white;
            border-color: transparent;

            &::placeholder {
                color: $t-gold;
            }
        }

        .btn-submit {
            border-color: transparent;
            color: white;
            font-weight: 700;
            background-color: $f-sunset;

            &::before {
                @include border-left(15px solid white);
            }
        }
    }
    // Travel Agent
    #callTA {
        border-color: $t-standard;
    }
    // Content Intro
    .content-intro {
        .section-header {
            &__text {
                &::after {
                    background-color: $f-green;
                    width: 80px;
                    @include margin-left(0);
                }
            }
        }

        .features-special {
            border-top-color: #9EA4A9;

            .t-s {
                font-weight: 700;
            }
        }
    }
    // Content Intro Complex
    .content-intro-complex {
        &__currency {
            font-weight: 700;
        }
    }
    // Category Select
    .section-category {
        &__select {
            color: $f-green;
            border-bottom-color: $f-green;
        }

        .section__heading {
            font-weight: 400;
        }
    }

    .section-category.gallery-changer {
        .category-toggle {
            ul {
                li {
                    &.selected {
                        border-bottom-color: $f-green;
                    }
                }
            }
        }
    }

    .select-wrapper {
        &::after {
            border-top-color: $f-green;
        }
    }
    // Property Overview
    // Weather Widget
    .weather-widget {
        &__localtime,
        &__weather {
            span {
                font-weight: 700;
            }
        }
    }
    // RTE block
    .rte-block {
        &__heading {
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: capitalize;
            font-size: 1.125rem;

            &.t-underline {
                &::after {
                    height: 0;
                }
            }
        }

        [class*='glyph-'] {
            color: $t-standard;
        }

        &.icon-list ul li {
            font-weight: 600;

            .list-item {
                &::after {
                    background-color: $t-standard;
                }
            }
        }

        + .features-special.features-small {
            padding-top: 0;
        }
    }
    // Checkin Widget
    .checkin-widgets {
        .checkin,
        .checkout {
            color: $t-standard;
            font-weight: 700;
        }

        span {
            color: $t-standard;
        }
    }
    // Feature Special
    .features-special {
        [class^='glyph'] {
            color: $t-standard;
        }

        .glyph-stargazing-fill {
            &:before {
                background-color: $t-standard;
            }
        }
    }
    // Trip Advisor
    .trip-advisor {
        &__link {
            color: $f-green;

            &:hover {
                border-bottom: $f-green;
            }
        }
    }
    // Notification
    .notification {
        color: #5D6770;
        border-color: #5D6770;

        [class^="glyph-"],
        [class*=" glyph-"] {
            color: #5D6770;
            border-color: #5D6770;
        }

        &-header {
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: capitalize;
        }

        .notification-text {
            color: #5D6770;
        }
    }

    .section--white {
        .notification {
            background: $f-grey-light-border;
        }
        .listing-with-gallery {
            &__wrapper,
            &__content,
            &__cta {
                background-color: white;
            }

            &__price {
                background-color: $f-grey-faint;
            }
        }
        .card {
            &__content {
                background: $f-pale-blue;
            }
        }
    }

    .section--light-grey {
        .notification {
            background: white;
        }
    }
    // App banner
    .app-section {
        .app-right-container {
            .text-group {
                a {
                    text-decoration: none;
                }
            }
        }
    }
    // Carousel Tabs
    .carousel-tabs {
        &__nav {
            @include desktop {
                @include margin-left(-2%);
            }

            @include lgDesktop {
                @include margin-left(-5%);
            }

            .carousel-tabs__cat {
                color: $t-standard;
                font-weight: 700;
                letter-spacing: 0;

                &::before {
                    background: #B2C8EA;
                }

                @include desktop {
                    &:last-child {
                        &::before {
                            border-top-right-radius: 8px;

                            @include rtl {
                                border-top-right-radius: 0;
                                border-top-left-radius: 8px;
                            }
                        }
                    }

                    &:first-child {
                        &::before {
                            border-top-left-radius: 8px;

                            @include rtl {
                                border-top-left-radius: 0;
                                border-top-right-radius: 8px;
                            }
                        }
                    }
                }

                &:hover::before,
                &:focus::before {
                    background: #87a1c7;
                }

                &.is-active {
                    color: white;

                    @include desktop {
                        &::before {
                            border-bottom-right-radius: 8px;
                            border-bottom-left-radius: 8px;
                        }

                        &:first-child {
                            &::before {
                                border-top-left-radius: 8px;
                                border-bottom-right-radius: 8px;
                                border-bottom-left-radius: 0;

                                @include rtl {
                                    border-top-left-radius: 0;
                                    border-bottom-right-radius: 0;
                                    border-top-right-radius: 8px;
                                    border-bottom-left-radius: 8px;
                                }
                            }
                        }

                        &:last-child {
                            &::before {
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;
                                border-bottom-left-radius: 8px;

                                @include rtl {
                                    border-top-right-radius: 0;
                                    border-top-left-radius: 8px;
                                }
                            }
                        }
                    }


                    &::before,
                    &:hover::before,
                    &:focus::before {
                        background: $f-bright-blue;
                    }
                }

                &::after {
                    border: 0;
                    width: 50px;
                    height: 2px;
                    @include margin-left(0);
                    display: block;
                    background-color: white;
                    transform: translateX(-50%);

                    @include rtl {
                        transform: translateX(50%);
                    }
                }
            }

            .slick-arrow {
                background: $f-gold;

                &.slick-next {
                    &::before {
                        border-left-color: white;

                        @include rtl {
                            border-right-color: white;
                        }
                    }
                }

                &.slick-prev {
                    &::before {
                        border-right-color: white;

                        @include rtl {
                            border-left-color: white;
                        }
                    }
                }
            }
        }

        &__item {
            .carousel-tabs__cat {
                background: #B2C8EA;
                font-weight: 700;
            }

            &.is-active {
                .carousel-tabs__cat {
                    background: $f-bright-blue;
                }
            }
        }

        &__title {
            font-weight: 600;
            color: $t-gold;
        }

        &__content {
            color: #5D6770;

            @include mobile {
                background: $f-grey-light-border;
            }
        }

        &__ctas {
            .btn {
                display: inline-flex;

                &.t-xs {
                    letter-spacing: 1px;
                }
            }
        }

        .gallery-slide,
        .gallery {
            .slick-list {
                @include desktop {
                    border-bottom-left-radius: 8px;

                    @include rtl {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 8px;
                    }
                }
            }

            &__header {
                color: $t-standard;
            }

            &__image {
                > .content {
                    margin-top: 0;
                }
            }

            ~ .carousel-tabs__description,
            &__text {
                background: $f-grey-light-border;

                @include mobile {
                    background: transparent;
                    box-shadow: none;
                }
            }

            .slick-next {
                &::before {
                    @include border-left(20px solid $f-gold)
                }
            }

            .slick-prev {
                &::before {
                    @include border-right(20px solid $f-gold)
                }
            }

            .slick-dots {
                li {
                    button {
                        &:before {
                            border-color: $f-green;
                        }
                    }

                    &.slick-active {
                        button {
                            &:before {
                                background-color: $f-green;
                            }
                        }
                    }
                }
            }
        }

        .feature {
            &__item {
                [class^='glyph'] {
                    color: #5D6770;
                }
            }
        }
    }

    .section--light-grey,
    .section--grey {
        .carousel-tabs {
            .gallery-slide,
            .gallery {
                ~ .carousel-tabs__description,
                &__text {
                    background: white;
                }
            }
        }
    }
    // Gallery Slide
    .gallery-slide,
    .gallery {
        .btn--ghost,
        .btn-avani-white {
            @include no-desktop {
                color: $f-gold;
            }
        }
    }
    // Testimonial
    .testimonial {
        .facility-rating {
            background-color: rgba(255, 255, 255, 0.8);
            padding: 32px 48px;

            &__heading {
                color: $t-standard;

                @include tablet {
                    &::after {
                        height: 100%;
                    }
                }
            }

            .t-gold {
                color: $f-green !important;
                font-size: 3.8rem;
            }

            &__score {
                color: $t-standard;
            }

            &__dots {
                color: $f-green;
            }
        }

        &__box {
            background-color: rgba(255, 255, 255, 0.8);
            padding: 32px 15px;

            @include tablet {
                padding: 32px 40px;
            }

            &.fullwidth {
                @include no-desktop {
                    @include margin-left(0)
                }

                .slick-prev {
                    @include mobile {
                        @include left(-25px);
                    }

                    @include tablet {
                        @include left(-40px);
                    }
                }

                .slick-next {
                    @include no-desktop {
                        @include right(-40px);
                    }
                }

                .section-header {
                    @include mobile {
                        @include padding-left(0)
                    }
                }
            }
        }
    }

    .slider.quotes {
        .slick-prev {
            @include tablet {
                @include left(-30px);
            }
        }

        .slick-next {
            @include tablet {
                @include right(-30px);
            }
        }

        .slick-slide {
            @include tablet {
                margin-left: 0;
            }
        }
    }
    // Location Map

    .location-map {

        .main-content {
            &__heading {
                font-weight: 600;
            }

            .location {
                &-description {
                    color: #5D6770;

                    span {
                        color: #5D6770;
                    }
                }
            }
        }

        .information-content {
            .information__header {
                background-color: $f-gold;
            }
        }

        .nearby-content {
            &__heading {
                font-weight: 700;
                letter-spacing: 1px;
                color: $f-green;
            }
        }

        &-list {
            > li {
                border-bottom-color: #5D6770;

                button {
                    position: relative;

                    &::after {
                        content: '';
                        transition: rotate 0.3s ease;
                        bottom: 5px;
                        @include right(10px);
                        width: 10px;
                        display: block;
                        position: absolute;
                        border-top: 10px solid $f-green;
                        border-right: 7.3333333333px solid transparent;
                        border-left: 7.3333333333px solid transparent;
                    }
                }

                &.is-active {
                    button::after {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .location-title {
            font-weight: 700;
        }

        i {
            display: none;
        }
    }

    .coordinates {
        .label,
        &-group {
            color: #5D6770;
        }
    }
    // FAQ
    .accordion {
        &__title {
            &::after {
                color: $f-green;
            }
        }

        &__content {
            background: $f-grey-light-border;
        }
    }
    // Inline Contact
    .contact-inline {
        &__heading {
            &.t-gold {
                color: white !important;
                font-weight: 700;
            }
        }

        &__extra {
            strong {
                color: $f-green;
                font-weight: 700;
            }
        }

        .t-c-m {
            a:not(.btn):not(.text-cta) {
                color: white;
            }
        }

        .btn--ghost {
            border-color: $f-green;

            &:hover,
            &:focus {
                background-color: #0c7565;
                color: white;
            }

            &.btn--gold {
                color: white !important;
            }
        }
    }

    .section--dark-grey,
    .full-bg {
        .contact-inline {
            color: white;
        }
    }
    // Listing with Gallery
    .listing-with-gallery {
        &__title {
            font-weight: 600;

            a {
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            &::after {
                content: '';
                margin-top: 10px;
                margin-bottom: 10px;
                display: block;
                width: 116px;
                height: 1px;
                background-color: $f-green;
            }
        }

        &__price {
            &-caption {
                letter-spacing: 1px;
                font-weight: 700;
                font-size: 1.625em;
            }
        }

        &__cta {
            .btn {
                font-weight: 600;
                letter-spacing: 1px;

                &.btn--ghost {
                    border-color: $f-gold;
                }
            }
        }

        &--wide {
            .listing-with-gallery__content {
                @include desktop {
                    padding-left: 32px;
                    padding-right: 32px;
                }
            }
        }
    }

    .flex-strect-btn {
        width: 100%;
    }
    // Current Search
    .current-search {
        &__container {
            background: $f-grey-light-border;

            .t-s {
                font-weight: 700;
            }
        }

        &__summary {
            .summary-item {
                .item_title {
                    color: $f-gold-overlay;
                }

                .item_subtitle {
                    font-weight: 700;
                }
            }
        }
    }
    // Rate
    .rate {
        background-color: $f-grey-light-border;

        &__title {
            font-weight: 600;
        }

        &__name {
            font-weight: 700;
        }

        &__content {
            .rate-name {
                .discovery {
                    background-color: $f-green;
                }

                .standard {
                    background-color: #9EA4A9;
                }
            }

            .policy-item {
                .t-xs {
                    font-weight: 700;
                    text-transform: capitalize;
                }
            }
        }

        .price__currency-wrap {
            .currency-converter {
                .form .form-main {
                    select {
                        color: $f-green;
                    }

                    &:after {
                        border-top-color: $f-green;
                    }
                }
            }
        }

        .price_container {
            .option {
                background-color: white;
                border-color: #9EA4A9;

                &.member_price {
                    border: 1px solid $f-green;
                    background-color: white;
                }
            }
        }

        .price_label {
            border-radius: 0;

            &.standard {
                background-color: #9EA4A9;
            }

            &.member {
                background-color: $f-green;
            }
        }

        .features-special {
            color: $f-green;

            .feature__item {
                [class^='glyph'] {
                    color: $f-green;
                }

                &.non-refundable {
                    &::before {
                        font-size: 18px;
                        top: 4px;
                    }
                }
            }
        }

        .roomInfo {
            ul {
                li {
                    color: $t-standard;
                }
            }
        }

        .brand-benefits {
            .t-gold {
                color: $t-standard !important;
                text-transform: capitalize !important;
                font-weight: 700;
                letter-spacing: 1px;
            }
        }

        .roomMarketing li {
            font-weight: 400;

            [class^='glyph-check-circle-outline'] {
                color: $f-green;
            }
        }

        .text-cta {
            color: $f-green;
        }
    }
    // Privilege Discount
    .privilege-discount {
        border-top-color: #9EA4A9;
        border-bottom-color: #9EA4A9;
    }
    // Event Listing
    .events-listing {
        background-color: rgba(255, 255, 255, 0.8);
    }
    // Meeting Room
    .meeting-room {
        &__types {
            &__title {
                font-weight: 600;
                text-transform: capitalize !important;
            }
        }
    }
    // Alternate Column
    .alternate-content {
        .section-header {
            &__text {
                color: $f-green;
            }
        }
    }
    // Full Bg
    .full-bg {
        .section-footer {
            &__text {
                color: white;
            }
        }
    }
    // Business Travel
    // 4 Grids
    .card {
        &__title {
            font-weight: 600;
        }

        &__image {
            // a {
            //     border-top-right-radius: 30px;
            //     border-top-left-radius: 10px;
            //     @include rtl {
            //         border-top-right-radius: 10px;
            //         border-top-left-radius: 30px;
            //     }
            // }
        }

        &__detail {
            font-weight: 700;
            letter-spacing: 0;
        }

        &__cta {
            a {
                font-weight: 700;
            }
        }
    }

    // B2B Form
    .b2b-form {
        .col-8 {
            background: $f-green;

            form {
                position: relative;
                z-index: 1;

                &:not(.section-category__form):not(.section-category-tab__form):not(#booking-mask-form):not(#booking-mask-form--fmb) {
                    label.t-sm,
                    .field-title {
                        color: white;
                        font-weight: 700;
                    }

                    .glyph {
                        z-index: 2;

                        &::before {
                            color: $t-standard;
                        }
                    }

                    input[type="checkbox"],
                    input[type="hidden"] {
                        + label {
                            color: white;
                        }
                    }

                    input,
                    select,
                    textarea {
                        border: 1px solid transparent;
                        border-radius: 3px;
                        background-color: rgba(255,255,255,.5);
                        color: $t-standard;

                        &:focus {
                            // border: 1px solid #12bda3;
                        }

                        &::placeholder {
                            color: $t-standard;
                        }
                    }

                    .js-inline-popup-trigger {
                        .glyph {
                            background: white;
                            color: $f-green;
                        }

                        &:hover {
                            .glyph {
                                background: transparent;
                                color: white;
                                border-color: white;
                            }
                        }
                    }

                    .ms-search {
                        input {
                            border-color: $f-grey-light;
                        }
                    }
                }
            }

            .required {
                opacity: 0.5;
            }

            .ms-options-wrap {
                > button,
                > button:focus {
                    background-color: rgba(255,255,255,.5);
                    color: $t-standard;
                    border-radius: 3px;
                    border-color: transparent;
                    font-size: 1em;

                    &::after {
                        border-top-color: $t-standard;
                    }
                }
            }

            &::after {
                content: '';
                height: 100%;
                width: 50vw;
                display: block;
                position: absolute;
                @include left(50%);
                top: 0;
                bottom: 0;
                z-index: 0;
                background-color: $f-green;

                @include no-desktop {
                    width: 100vw;
                    @include left(-30px);
                }

                @include tablet {
                    @include left(-40px);
                }
            }
        }

        .form {
            .t-c-m a:not(.btn):not(.text-cta) {
                color: white;
                text-decoration: underline;
            }

            .btn--gold {
                background: white;
                color: $f-green !important;
                font-weight: 700;

                &:focus,
                &:hover {
                    background: $f-pale-blue;
                }
            }

            &-validation-summary {
                color: white;
            }
        }
    }
    // Contact Carousel
    .content-carousel {
        .slick-anantara-pagination {
            .slick-dots {
                li {
                    font-weight: 700;
                    margin: 0 3px;
                    padding: 15px 0 0;

                    button {
                        min-width: 120px;
                    }

                    span {
                        font-size: 1em;
                        font-weight: 700;
                    }

                    &.slick-active button {
                        background-color: $f-green;
                    }
                }
            }
        }

        .carousel-slide-title {
            font-size: 1.15em;
            font-weight: 600;
        }
    }
	
    // B2B Brand logo
    .brand-logo {
        .main-footer-brand {
            @include desktop {
                @include border-right(1px solid $f-gold);
            }

            @include landscapeTablet {
                @include border-right(1px solid $f-gold);
            }
        }
    }
    // Partner Logo
    .partners_logo {
        .image-highlight {
            &__container {
                justify-content: space-around;
            }

            &__item {
                flex-basis: 100%;

                @include tablet {
                    flex-basis: 45%;
                }

                @include desktop {
                    flex-basis: 15%;
                }
            }
        }
    }
    // Text Seperator
    .text-separator {
        &--light::before {
            background-color: $t-standard;
        }

        &__text {
            font-weight: 700;
        }
    }
    // Detail Block
    .detail-block {
        &__location {
            font-weight: 700;
            font-size: 1em;
        }
    }
    // Accordion
    .accordion {
        &__item {
            border-bottom-color: $f-op35-grey-dark-border;
        }
    }
    // Grid
    .grid-item {
        > .content {
            margin: 0;
        }
    }
    // Slick
    .slick-dots {
        li {
            button {
                &::before {
                    border-color: $f-green;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        background-color: $f-green;
                    }
                }
            }
        }
    }
    // Calendar
    .litepicker {
        .container {
            &__days {
                .day-item {
                    &.is-in-range {
                        background-color: #CED1D4;
                    }

                    &.is-start-date,
                    &.is-end-date {
                        background-color: $f-green;
                    }
                }
            }

            &__months {
                @include desktop {
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }
        }
    }

    .month-item {
        border-right-color: #ccc;

        @include rtl {
            border-left-color: #ccc;
        }
    }

    .horizon {
        .calendar-container {
            @include desktop {
                border-radius: 8px;
                padding: 0px 20px 20px;
                background-color: $f-bright-blue;
                top: calc(100% - 15px);
            }

            .calendar-header {
                @include desktop {
                    border-top-right-radius: 4px;
                    border-top-left-radius: 4px;
                }
            }
        }
    }
    // Gallery Grid
    &.horizon-header {
        .gallery__grid {
            &.header-banner {
                @include desktop {
                    margin-top: 0;
                }
            }
        }

        .is-scrolled {
            .gallery__grid {
                &.header-banner {
                    @include desktop {
                        margin-top: 0px;
                    }
                }
            }
        }
    }
    // Banner search
    .banner-search {
        &__content {
            select {
                color: $f-green;
            }
        }
    }
    // Category Tabs
    .tabs {
        .tab {
            &__title {
                > div {
                    color: $f-green;
                }
            }

            &__item {
                &:hover,
                &:focus {
                    border-bottom-color: $f-green;
                }
            }
        }

        &.is--sticky {
            background: #CED1D4;

            .tab {
                &__title {
                    > div {
                        color: $t-standard;
                    }
                }

                &__item {
                    &:hover,
                    &:focus {
                        border-bottom-color: $t-standard;
                    }
                }
            }
        }
    }

    &.horizon-header {
        .tabs {
            &.is--sticky {
                // top: 130px;

                @include desktop {
                    top: 161px;
                    font-size: 0.7em;
                    box-shadow: inset 0px 10px 7px -8px rgba(0, 0, 0, 0.12)
                }
            }
        }

        .is-scrolled {
            .tabs {
                &.is--sticky {
                    background: #CED1D4;
                    color: $t-standard;

                    @include desktop {
                        top: 60px;
                    }
                }
            }
        }

        &.with-notice {
            .tabs {
                &.is--sticky {    
                    @include desktop {
                        top: 213px;
                    }
                }
            }

            .is-scrolled {
                .tabs {
                    &.is--sticky {    
                        @include desktop {
                            top: 60px;
                        }
                    }
                }
            }
        }
    }
    // Blog CTA
    .blog-cta {
        &--dot {
            &::after {
                // background-color: $f-green;
            }
        }

        &__heading,
        &__date {
            color: $t-standard !important;
        }

        &__author {
            letter-spacing: 1px;
        }

        &__offscreen {
            .text-cta {
                color: $f-green;
            }
        }
    }
    // Blog
    .blog {
        &__heading {
            color: $t-standard !important;
        }

        &__category {
            color: $t-standard !important;
        }
    }

    .link-readmore {
        color: $f-green !important;         
    }
}
